document.addEventListener("DOMContentLoaded", function () {
  /* ---------------- TIMETABLES ON SELECT & READING MORE ELEMENTS ON MOBILE ---------------------- */
  $(function (e) {
    /* ---------------- TIMETABLES ON SELECT -------------------------------------------------- */
    var oBus = $("#busLine");
    $("#busLine")
      .select2({
        placeholder: "Wybierz linię",
        language: {
          noResults: function () {
            return "Brak wyników";
          },
        },
      })
      .on("select2:opening", function (e) {
        if (!oBus.hasClass("loaded")) {
          e.preventDefault();
          oBus.parent().addClass("loading");
          $.ajax({
            method: "GET",
            url: "https://www.wroclaw.pl/komunikacja/bus/getDataByAjax?action=get-lines-list",
            dataType: "json",
            cache: true,
          }).done(function (oData) {
            var dataArray2 = oData.line;
            dataArray2.forEach(function (currentValue, index, arr) {
              var newOption = new Option(
                dataArray2[index].name,
                dataArray2[index].url,
                false,
                false
              );
              oBus.append(newOption);
            });
            oBus.addClass("loaded");
            oBus.parent().removeClass("loading");
            oBus.select2("open");
          });
        }
      })
      .on("select2:selecting", function (e) {
        window.location = e.params.args.data.id;
      });

    var oStreets = $("#street");
    oStreets
      .select2({
        placeholder: "Wybierz przystanek",
        // ajax: {
        //     url: 'https://proxy.dev.wroclaw.pl/tools/proxy/getlist.php?json',
        //     dataType: 'json'
        // },
        language: {
          noResults: function () {
            return "Brak wyników";
          },
        },
      })
      .on("select2:opening", function (e) {
        if (!oStreets.hasClass("loaded")) {
          e.preventDefault();
          oStreets.parent().addClass("loading");
          $.ajax({
            method: "GET",
            url: "https://www.wroclaw.pl/komunikacja/bus/getDataByAjax?action=get-stops-list",
            dataType: "json",
            cache: true,
          }).done(function (oData) {
            var dataArray2 = oData.street;
            dataArray2.forEach(function (currentValue, index, arr) {
              var newOption = new Option(
                dataArray2[index].name,
                dataArray2[index].url,
                false,
                false
              );
              oStreets.append(newOption);
            });
            oStreets.addClass("loaded");
            oStreets.parent().removeClass("loading");
            oStreets.select2("open");
          });
        }
      })
      .on("select2:selecting", function (e) {
        window.location = e.params.args.data.id;
      });
  });
});
